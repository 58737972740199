import React, { useState } from "react";
import { errorMessages, globalConstants } from "@/components/common/globalConstants";
import { Modal } from "react-bootstrap";
import { ModalValues } from "@/pages";
import { useForgotPassword } from "@/services/apiMethod";

interface ForgotPasswordModalProps {
  isOpen: boolean;
  setIsOpen: (value: ModalValues) => void;
  setLoading: (value: boolean) => void;
  loading: boolean;
  showSuccessMessage: (value: string) => void;
}

function ForgotPasswordModal({ isOpen, setIsOpen, setLoading, loading, showSuccessMessage }: ForgotPasswordModalProps) {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const { forgotPassword, isLoading } = useForgotPassword();

  /** Validate forgot password form */
  const validate = () => {
    let valid = true;

    // Check if the required fields are not empty
    if (!email.trim()) {
      setError(errorMessages.required_field);
      valid = false;
    }
    // Check if the email is in a valid format using a basic regex pattern
    if (email && !globalConstants.email_regex.test(email)) {
      setError(errorMessages.invalid_email);
      valid = false;
    }
    return valid;
  };

  const onForgotPasswordSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const isValid = validate();
    if (isValid) {
      setLoading(true);
      const res = await forgotPassword([{ email }, { "Content-Type": "application/json; charset=utf-8" }]);
      if (res.status === "OK" && !isLoading) {
        showSuccessMessage("We have sent a reset password link on your email.");
        setLoading(false);
        setIsOpen({ login: true, forgetPassword: false });
      }
    }
  };

  return (
    <Modal show={isOpen} dialogClassName="login-modal" className="login-modal-wrapper">
      <Modal.Body>
        <div className="loginInner">
          <h4>Forgot Password</h4>
          <p>Enter the email address associated with your account and we&#39;ll send you a link to reset your password</p>
          <div className="controlWrap">
            <form onSubmit={onForgotPasswordSubmit}>
              <div className={`control ${error ? "invalid" : "valid"}`}>
                <label>EMAIL</label>
                <input type="text" name="forgotPassEmail" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="EMAIL" />
                {error && <div className="error">{error}</div>}
              </div>
              <div className="control">
                <button className="btnBorderGreen" type="submit" disabled={loading}>
                  Submit
                </button>
                <a className="forgot-pass-label" onClick={() => setIsOpen({ login: true, forgetPassword: false })}>
                  Back to login
                </a>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default ForgotPasswordModal;
