import { useEffect } from "react";
import Layout from "@/components/layout/Layout";
import { useState } from "react";
import { svgIcons } from "@/styles/svgIcon/svgIcon";
import { useSearchParams } from "next/navigation";
import { PageLoader } from "@/components/common/Loader";
import LoginModal from "@/components/landing/LoginModal";
import ForgotPasswordModal from "@/components/landing/ForgotPasswordModal";
import { useRouter } from "next/router";
import { useSession } from "next-auth/react";

export interface ModalValues {
  login: boolean;
  forgetPassword: boolean;
}

export type AlertType = "success" | "danger" | "";
export interface MessageType {
  show: boolean;
  msg: string | string[];
  type: AlertType;
}
export default function Login() {
  const [showLoginModal, setShowLoginModal] = useState<boolean>(true);
  const [showForgetPasswordModal, setShowForgetPasswordModal] =
    useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const searchParams = useSearchParams();
  const router = useRouter();
  const callbackUrl = searchParams.get("callbackUrl") || "/dashboard";
  const [message, setMessage] = useState<MessageType>({
    show: false,
    msg: "",
    type: "success",
  });
  const { data: session } = useSession();

  useEffect(() => {
    if(session && session.user){
      router.push(callbackUrl);
    }
  }, [session]);

  const showSuccessMessage = (
    msg: string | string[],
    type: AlertType = "success"
  ) => {
    setMessage({ msg: msg, show: true, type });
    setTimeout(() => {
      setMessage({ msg: "", show: false, type });
    }, 5000);
  };

  useEffect(() => {
    if (router.query.message) {
      !showLoginModal && setShowLoginModal(true);
      const type: AlertType = router.query.type
        ? (router.query.type as AlertType)
        : "";
      showSuccessMessage(router.query.message, type ? type : "success");
    }
  }, [router.query.message, router.query.type]);

  return (
    <>
      {loading && <PageLoader />}
      <div className="loginWrap">
        {svgIcons.metaLogo}

        <button
          type="button"
          onClick={() => {
            setShowLoginModal(true);
            setShowForgetPasswordModal(false);
          }}
          className="cus-btn buttom-small"
        >
          Log in
        </button>

        {showLoginModal && (
          <LoginModal
            isOpen={showLoginModal}
            setIsOpen={(data: ModalValues) => {
              setShowLoginModal(data.login);
              setShowForgetPasswordModal(data.forgetPassword);
            }}
            setLoading={setLoading}
            callbackUrl={callbackUrl}
            loading={loading}
            message={message}
          />
        )}
        {showForgetPasswordModal && (
          <ForgotPasswordModal
            isOpen={showForgetPasswordModal}
            setIsOpen={(data: ModalValues) => {
              setShowLoginModal(data.login);
              setShowForgetPasswordModal(data.forgetPassword);
            }}
            setLoading={setLoading}
            loading={loading}
            showSuccessMessage={showSuccessMessage}
          />
        )}
      </div>
    </>
  );
}
