import { useSearchParams } from "next/navigation";
import React, { ChangeEvent, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { errorMessages, globalConstants } from "@/components/common/globalConstants";
import { signIn } from "next-auth/react";
import { useRouter } from "next/router";
import { MessageType, ModalValues } from "@/pages";
interface FormValues {
  email: string;
  password: string;
}

interface LoginModalProps {
  isOpen: boolean;
  setIsOpen: (value: ModalValues) => void;
  setLoading: (value: boolean) => void;
  callbackUrl: string;
  loading: boolean;
  message: MessageType;
}

function LoginModal({ isOpen, setIsOpen, setLoading, callbackUrl, loading, message }: LoginModalProps) {
  const [formValues, setFormValues] = useState<FormValues>({ email: "", password: "" });
  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [isValidEmail, setIsValidEmail] = useState(true);
  const router = useRouter();
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
    setErrors({});

    if (name === "email" && value.trim() !== "") {
      setIsValidEmail(globalConstants.email_regex.test(value));
    }
  };

  /** a function to validate the form */
  const validateForm = () => {
    let valid = true;
    const newErrors = { email: "", password: "" };

    // Check if the required fields are not empty
    if (!formValues.email.trim()) {
      newErrors.email = errorMessages.required_field;
      valid = false;
    }

    if (!formValues.password.trim()) {
      newErrors.password = errorMessages.required_field;
      valid = false;
    }

    // Check if the email is in a valid format using a basic regex pattern

    if (formValues.email && !globalConstants.email_regex.test(formValues.email)) {
      newErrors.email = errorMessages.invalid_email;
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  /** action to call on form submit */
  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setLoading(true);
        setFormValues({ ...formValues, email: "", password: "" });

        const res = await signIn("credentials", {
          redirect: false,
          email: formValues.email,
          password: formValues.password,
          callbackUrl
        });
        setLoading(false);

        if (!res?.error) {
          router.push(callbackUrl);
        } else if (res?.status === 401) {
          setErrors({ ...errors, email: errorMessages.invalid_creds });
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };
  return (
    <Modal show={isOpen} dialogClassName="login-modal" className="login-modal-wrapper">
      <Modal.Body>
        <div className="loginInner">
          <h3>LOG IN</h3>
          <p>Login to access your account and explore our platform.</p>
          {message.show && (
            <Alert key={message.type} variant={message.type}>
              {message.msg}
            </Alert>
          )}
          <div className="controlWrap">
            <form onSubmit={onSubmit}>
              <div className={`control ${errors.email ? "invalid" : "valid"}`}>
                <label>EMAIL</label>
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  placeholder="EMAIL"
                />
                {errors.email && <div className="error">{errors.email}</div>}
              </div>
              <div className={`control ${errors.password ? "invalid" : "valid"}`}>
                <label>PASSWORD</label>
                <input type="password" name="password" value={formValues.password} onChange={handleChange} placeholder="PASSWORD" />
                {errors.password && <div className="error">{errors.password}</div>}
              </div>
              <div className="control">
                <button className="btnBorderGreen" type="submit" disabled={loading}>
                  Log in
                </button>
                <a className="forgot-pass-label" onClick={() => setIsOpen({ login: false, forgetPassword: true })}>
                  Forgot Password
                </a>
              </div>
            </form>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default LoginModal;
